.container {
    // max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    padding: 0 50px;
    @include media("<=laptop") {
        padding: 0 20px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        @include media("<=laptop") {
            flex-direction: column;
        }
    }
    >* {
        flex: auto
    }

    &.compact {
        max-width: 1000px;
    }
}
body {
    padding-bottom: var(--meta);
    animation: fadein 2s;
}

@keyframes bg {
    0%   { background-image: url("/dist/images/bgs/1.svg"); }
    14.3%   { background-image: url("/dist/images/bgs/2.svg"); }
    28.6%  { background-image: url("/dist/images/bgs/3.svg"); }
    42.8%  { background-image: url("/dist/images/bgs/4.svg"); }
    57.1% { background-image: url("/dist/images/bgs/5.svg"); }
    71.4% { background-image: url("/dist/images/bgs/6.svg"); }
    85.7% { background-image: url("/dist/images/bgs/7.svg"); }
}
.site {
    display: flex;
    flex-direction: column;
    min-height:100vh;
    // min-height: 800px;
    position: relative;
    z-index: 10;
    background-color: $bg;
    background-repeat: no-repeat;
    animation: bg 7s infinite step-end;
    background-size:cover;
    @include media("<=laptop") {
        min-height: 100vh;
    }
    @include media("<=tablet") {
        height: auto;
    }

    @include media("<=phone") {
        min-height: calc(var(--vh, 1vh) * 100);
        height: auto;
    }
    header,
    footer {
        flex: none;
    }
    main {
        flex: 1;
    }
}
//header
.inner {
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-family: $brand-heading;

    @include media("<=laptop") {
        padding: 20px;
        #colophon & {
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }
    }
}

.menu {
    @include media("<=laptop") {
        margin-bottom: 50px;
    }
}

.menu-link {
    display: flex;
    align-items: center;
    @include media("<=laptop") {
        margin-bottom: .5em;
        #colophon & {
            @include font-size(2);
            font-weight: 700;
        }
    }

    @include media("<=phone") {
        #colophon & {
            @include font-size(1.45);
        }
    }
}

svg {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.icon-dl {
    height: 18px;
}

//main
.site-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo {
    margin: 0 auto;
    display: block;
    width: 600px;
    padding: 0px;
    min-width: 280px;
    @include media("<=laptop") {
        width: 400px;
        padding: 20px 0;
    }
    @include media("<=tablet") {
        width: 270px;
        margin: 0px auto;
    }
    @include media("<=phone") {
        width: 270px;
        margin: 0px auto; 
        padding: 0px;
    }

}

//footer
#colophon {
    .inner {
        align-items: flex-end;
        @include media("<=laptop") {
            align-items: center;
            text-align: center;
        }

        @include media("<=phone") {
            //padding-bottom: 100px;
        }

    }
    .menu-link:first-child {
        position: absolute;
        top: 50px;
        right: 50px;
        @include media("<=laptop") {
            position: relative;
            top: initial;
            right: initial;
            justify-content: center;
        }
    }
}
.address {
    @include media("<=laptop") {
        margin-bottom: 50px;
    }   
    @include media("<=phone") {
        margin-bottom: 20px;
    }   
}
.info-row {
    display: flex;
    align-items: center;
    margin-bottom: .3em;
    @include media("<=laptop") {
        justify-content: center;
    }
}

#meta {
    @include font-size(1);
    padding: 50px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    background-color: $brand-primary;
    color: rgba($bg,.6);
    @include media("<=laptop") {
        padding: 40px 0;
    }

    a {
        color: currentColor;
        border-bottom: 1px solid rgba(240, 238, 223, 0.6);
        text-decoration: none;
    }
}
.minor {
    display: flex;
    margin-bottom: 30px;
    @include media("<=laptop") {
        margin-bottom: 10px;
    }
    a {
        margin-right: 2em;
    }
}

.copyright {
    @include media("<=laptop") {
        margin-bottom: 50px;
    }
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}