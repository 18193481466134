a {
	color: $brand-primary;
	// text-decoration: none;
	transition: all .2s ease;

	&:visited {
		color: $brand-primary;
	}

	&:hover,
	&:active {
		//color: $brand-secondary;
		transform: scale(0.9);



		// text-decoration: none;
		svg {
			fill: $brand-secondary;
			transform: scale(0.9);
			transition: all .2s ease;
		}
	}

	&:focus {
		outline: 2px dotted $brand-primary;
		// text-decoration: none;
	}

	&:hover,
	&:active {
		outline: 0;
	}
}
