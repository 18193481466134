h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin: 0 0 .8em 0;
    font-family: $brand-heading;
    margin: 0 0 .3em;
}

h1 {
    // @include fluid($h1);
    @include font-size(3);
    text-transform: uppercase;
}
h2 {
    // @include fluid($h1);
    @include font-size(2);
    text-transform: uppercase;
    @include media("<=laptop") {
        margin-bottom: 50px;
    }
    @include media("<=phone") {
        @include font-size(1.5);
        margin-bottom: 20px;
    }    
}