@import "includemedia";
@import "fluidtype";


// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
    font-size: ($sizeValue * 16) * 1px;
    font-size: $sizeValue * 1rem;
}

// example
// p {
//     @include fluid-type(14px, 20px);
//     font-weight: normal
// }


// Centering
@mixin center($pos:both) {
    position: absolute;

    @if ($pos==both) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @else if ($pos==top) {
        left: 50%;
        transform: translate(-50%, 0);
    }

    @else if ($pos==left) {
        top: 50%;
        transform: translate(0, -50%);
    }

    @else if ($pos==right) {
        top: 50%;
        right: 0;
        transform: translate(0, -50%);
    }

    @else if ($pos==bottom) {
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

// eg. @include center(both);
// Clearfix
@mixin clearfix() {
    content: "";
    display: table;
    table-layout: fixed;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
    clear: both;
}

// Clear after (not all clearfix need this also)
@mixin clearfix-after() {
    clear: both;
}

// Column width with margin
@mixin column-width($numberColumns: 3) {
    width: map-get($columns, $numberColumns) - (($columns__margin * ($numberColumns - 1)) / $numberColumns);
}

/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal scrollbar's height and vertical scrollbar's width
/// @param {Color} $foreground-color - Scrollbar's color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - Scrollbar's color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars($size, $foreground-color, $background-color) {

    // For Google Chrome
    ::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    ::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    ::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}