body,
button,
input,
select,
textarea {
	color: $brand-primary;
	font-family: $brand-font;
	@include font-size(1.4);
	line-height: 1.4;
	// @include fluid();
	// line-height: $font-line-height-body;
}
%vr {
	@include fluid-baseline(30px, .7, .7);
}

@import "headings";

@import "copy";

// @import "syntax";